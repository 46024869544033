/* Actividades */
.titulo{background-color:var(--blue);width:50%;color:#fff;position:absolute;z-index:2;top:0;left:0;}
.titulo__sep{height:.1rem;width:50%;background-color:#fff;margin:1rem 0;}

.actividades{display:flex;flex-wrap:wrap;}
.actividades_con{width:100%;display:flex;flex-wrap:wrap;}
.actividades_el{width:50%;position:relative;overflow:hidden;}
.actividades_el__link{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2;}
.actividades_el:first-child{margin-top:6rem;}
.half{margin-top:-6rem;}
.actividades_el__title{position:absolute;color:#fff;z-index:2;}
.actividades_el__img{width:100%;height:auto;transition:all .5s ease;}
.actividades_el:hover .actividades_el__img{transform:scale(1.1);}

.contenido_back{left:inherit;right:0;height:6rem;align-items:center;justify-content:flex-start;}
.contenido_back img{transform:rotate(0);}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .titulo{position:initial;padding:3rem 1rem;width:100%;}
    .actividades_el:first-child{margin-top:0;}
    .half{margin-top:0;}
    .titulo__t1{font-size:2rem;line-height:1.7rem;}
    .titulo__t2{font-size:1.5rem;line-height:1.8rem;}

    .actividades_el{width:100%;}
    .actividades_el__title{top:1rem;left:1rem;font-size:1.5rem;max-width:calc(100% - 2rem);}
}

@media (max-width:899px) and (min-width: 600px){
    .titulo{position:initial;padding:3rem 2rem;width:100%;}
    .actividades_el:first-child{margin-top:0;}
    .half{margin-top:0;}
    .titulo__t1{font-size:2.2rem;line-height:1.6rem;}
    .titulo__t2{font-size:1.8rem;line-height:2.2rem;}

    .actividades_el{width:100%;}
    .actividades_el__title{top:2rem;left:2rem;font-size:2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .titulo{padding:3rem 2rem;transform:translateY(calc(-100% + 6rem));}
    .titulo__t1{font-size:2.2rem;line-height:1.6rem;}
    .titulo__t2{font-size:1.8rem;line-height:2.2rem;}

    .actividades_el__title{top:2rem;left:2rem;font-size:1.8rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .titulo{padding:3rem 4rem;transform:translateY(calc(-100% + 6rem));}
    .titulo__t1{font-size:2.5rem;line-height:1.8rem;}
    .titulo__t2{font-size:2rem;line-height:2.5rem;}

    .actividades_el__title{top:2rem;left:2rem;font-size:2rem;}
}

@media (min-width:1400px){
    .titulo{padding:4rem 5rem;transform:translateY(calc(-100% + 6rem));}
    .titulo__t1{font-size:3rem;line-height:2.3rem;}
    .titulo__t2{font-size:2.2rem;line-height:2.7rem;max-width:38rem;}

    .actividades_el__title{top:3rem;left:3rem;font-size:2.5rem;}
}