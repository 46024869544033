/* Contenido */
.grupos_content{display:flex;flex-wrap:wrap;position:relative;}
.grupos_des, .grupos_opts{width:50%;}
.grupos_des{background-color:#16425b;color:#fff;}
.grupos_des__t1{margin-bottom:1rem;}
.grupos_des__t2{margin-bottom:.5rem;}
.grupos_des__t2:last-child{margin-bottom:0;}

.grupos_opts_el{position:relative;}
.grupos_opts_el a{position:absolute;top:0;left:0;width:100%;height:100%;}
.grupos_opts_el.empresarial{background-color:#3a7ca5;}
.grupos_opts_el.escolar{background-color:#2f6690;}
.grupos_opts_el__title{color:#16425b;margin-bottom:1.5rem;}
.grupos_opts_el__des{color:#fff;}
.grupos_opts_el__link{display:flex;align-items:center;text-decoration:none;color:#fff;margin-bottom:.5rem;}
.grupos_opts_el__link img{margin-left:.5rem;width:1rem;}

/* Responsive style */
@media (max-width: 599px){
    .grupos_des{width:100%;padding:3rem 1rem;}
    .grupos_opts{width:100%;}
    .grupos_opts_el{width:100%;padding:3rem 1rem;}
    
    .grupos_des__t1{font-size:1.8rem;line-height:1.8rem;}
    .grupos_des__t2{font-size:1.1rem;}

    .grupos_opts_el__title{font-size:1.8rem;}
    .grupos_opts_el__link, .grupos_opts_el__des{font-size:1.1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .grupos_des{width:100%;padding:3rem 2rem;}
    .grupos_opts{display:flex;width:100%;}
    .grupos_opts_el{width:50%;padding:3rem 2rem;}
    
    .grupos_des__t1{font-size:1.8rem;line-height:1.8rem;}
    .grupos_des__t2{font-size:1.1rem;}

    .grupos_opts_el__title{font-size:1.8rem;}
    .grupos_opts_el__link, .grupos_opts_el__des{font-size:1.1rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .grupos_des, .grupos_opts_el{padding:4rem 3rem;}
    
    .grupos_des__t1{font-size:1.8rem;line-height:1.8rem;}
    .grupos_des__t2{font-size:1.2rem;}

    .grupos_opts_el__title{font-size:1.8rem;}
    .grupos_opts_el__link, .grupos_opts_el__des{font-size:1.2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .grupos_des, .grupos_opts_el{padding:4rem;}
    
    .grupos_des__t1{font-size:2rem;}
    .grupos_des__t2{font-size:1.2rem;}

    .grupos_opts_el__title{font-size:2rem;}
    .grupos_opts_el__link, .grupos_opts_el__des{font-size:1.2rem;}
}

@media (min-width:1400px){
    .grupos_des, .grupos_opts_el{padding:5rem 6rem;}
    
    .grupos_des__t1{font-size:2.5rem;line-height:2.5rem;}
    .grupos_des__t2{font-size:1.3rem;}

    .grupos_opts_el__title{font-size:2.5rem;}
    .grupos_opts_el__link, .grupos_opts_el__des{font-size:1.3rem;}
}