/* Título */
.titulos{background:linear-gradient(to right,#314872 0%,#314872 50%,#81c3d7 50%,#81c3d7 100%);position:relative;}
.titulos_bg{position:absolute;top:0;left:50%;width:50%;height:100%;object-fit:cover;}
.titulos_content{display:flex;flex-wrap:wrap;}
.titulos_con{width:50%;}
.titulos_con_title{color:#fff;}
.titulos_con{color:#fff;}
.titulos_con__t1{margin-bottom:.5rem;}
.titulos_con__t2{margin-bottom:1rem;}
.titulos_con__link{color:#fff;background-color:#314872;padding:.5rem 2rem;text-decoration:none;display:block;width:fit-content;}

/* Espacios */
.espacios{display:flex;flex-wrap:wrap;}
.espacio{width:50%;position:relative;}
.espacio_bg{width:100%;height:auto;}
.espacio_des{position:absolute;z-index:2;bottom:1rem;left:3rem;color:#fff;text-shadow: 1px 1px 2px rgba(0 0 0 / 60%);}

/* Contacto */
.espacios_contacto{width:50%;background-color:#314872;color:#fff;}
.espacios_contacto__t1{margin-bottom:1rem;}
.espacios_contacto__t2{margin-bottom:1rem;}
.espacios_contacto__link{color:#fff;background-color:var(--blue2);padding:.5rem 1rem;text-decoration:none;display:block;width:fit-content;}

/* Responsive style */
@media (max-width: 599px){
    .titulos{background:linear-gradient(to bottom,#314872 0%,#314872 50%,#81c3d7 50%,#81c3d7 100%);}
    .titulos_bg{height:50%;width:100%;left:0;top:50%;}
    .titulos_con{width:100%;}
    .titulos_con:nth-child(1){padding:0 0 5rem 0;}
    .titulos_con:nth-child(2){padding:0;}
    .titulos_con_title{font-size:2rem;line-height:2rem;max-width:20rem;}
    .titulos_con__t1{font-size:2rem;}
    .titulos_con__t2{font-size:1.2rem;max-width:25rem;}

    .espacio{width:100%;}
    .espacio_des{left:1rem;bottom:1.5rem;}
    .espacio_des__t1{font-size:1.8rem;line-height:2rem;}
    .espacio_des__t2{font-size:1rem;}

    .espacios_contacto{width:100%;padding:2rem 1rem;}
    .espacios_contacto__t1{font-size:1.8rem;line-height:1.8rem;}
    .espacios_contacto__t2, .espacios_contacto__link{font-size:1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .titulos{background:linear-gradient(to bottom,#314872 0%,#314872 50%,#81c3d7 50%,#81c3d7 100%);}
    .titulos_bg{height:50%;width:100%;left:0;top:50%;}
    .titulos_con{width:100%;}
    .titulos_con:nth-child(1){padding:0 1rem 7rem 1rem;}
    .titulos_con:nth-child(2){padding:0 1rem 1rem 1rem;}
    .titulos_con_title{font-size:2.5rem;line-height:2.5rem;max-width:25rem;}
    .titulos_con__t1{font-size:2rem;}
    .titulos_con__t2{font-size:1.2rem;max-width:25rem;}

    .espacio{width:100%;}
    .espacio_des{left:3rem;bottom:3rem;}
    .espacio_des__t1{font-size:2rem;line-height:2.2rem;}
    .espacio_des__t2{font-size:1.1rem;}

    .espacios_contacto{width:100%;padding:3rem;}
    .espacios_contacto__t1{font-size:2rem;line-height:2rem;}
    .espacios_contacto__t2{max-width:30rem;}
    .espacios_contacto__t2, .espacios_contacto__link{font-size:1.1rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .titulos_con{padding:2rem;}
    .titulos_con_title{font-size:2rem;line-height:2rem;max-width:19rem;margin:auto;}
    .titulos_con__t1{font-size:1.8rem;}
    .titulos_con__t2{font-size:1.1rem;max-width:25rem;}
    
    .espacio_des{left:1.5rem;}
    .espacio_des__t1{font-size:1.8rem;line-height:2rem;}
    .espacio_des__t2{font-size:1rem;}

    .espacios_contacto{padding:2rem 2rem 1rem 2rem;}
    .espacios_contacto__t1{font-size:1.8rem;line-height:1.8rem;margin-bottom:.6rem;}
    .espacios_contacto__t2{margin-bottom:.6rem;}
    .espacios_contacto__t2, .espacios_contacto__link{font-size:1rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .titulos_con{padding:2rem;}
    .titulos_con_title{font-size:2.5rem;line-height:2.5rem;max-width:24rem;margin:auto;}
    .titulos_con__t1{font-size:2rem;}
    .titulos_con__t2{font-size:1.2rem;max-width:30rem;}

    .espacio_des__t1{font-size:2rem;line-height:2.1rem;}
    .espacio_des__t2{font-size:1rem;}

    .espacios_contacto{padding:3rem;}
    .espacios_contacto__t1{font-size:2rem;}
    .espacios_contacto__t2{max-width:30rem;}
    .espacios_contacto__t2, .espacios_contacto__link{font-size:1.1rem;}
}

@media (min-width:1400px){
    .titulos_con{padding:2rem;}
    .titulos_con_title{font-size:2.8rem;line-height:2.8rem;max-width:27rem;margin:auto;}
    .titulos_con__t1{font-size:2.2rem;}
    .titulos_con__t2{font-size:1.3rem;max-width:33rem;}

    .espacio_des{left:4rem;bottom:2rem;}
    .espacio_des__t1{font-size:2.2rem;line-height:2.5rem;}
    .espacio_des__t2{font-size:1.2rem;}

    .espacios_contacto{padding:4rem;}
    .espacios_contacto__t1{font-size:2.2rem;}
    .espacios_contacto__t2{max-width:30rem;}
    .espacios_contacto__t2, .espacios_contacto__link{font-size:1.2rem;}
}