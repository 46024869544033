/* HEADER */
.header_title.aviario{background-color:var(--navy);}
.header_title.costa{background-color:#006853;}
.header_title.mar{background-color:#0075c9;}
.header_title.tierra{background-color:#bc955c;}

/* habitat */
.habitat__t1{width:calc(50% - 4rem);margin-left:auto;margin-bottom:1rem;color:#636363;}

.habitat_con{display:flex;flex-wrap:wrap;justify-content:center;padding-top:3rem;}
.habitat_con_el{text-align:center;position:relative;}
.habitat_con_el a{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2;}
.habitat_con_el__img{width:80%;max-width:10rem;margin:auto;margin-bottom:1rem;}
.habitat_con_el__t1{color:var(--navy);}
.habitat_con_el__t2{color:#636363;}

.preguntas{max-width:900px;margin:auto;padding:4rem 0;}
.preguntas_el{margin-bottom:1rem;}
.preguntas_el__t1{color:var(--navy);border-bottom:1px solid var(--yellow);padding-bottom:.5rem;margin-bottom:.5rem;font-weight:900;}
.preguntas_el__t2{color:#636363;}

.relacionados{display:flex;justify-content:center;}
.relacionados_el{position:relative;}
.relacionados_el:last-child{margin-right:0;}
.relacionados_el img{width:100%;height:auto;}
.relacionados_el_des{position:absolute;z-index:2;color:#fff;font-weight:900;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .des{font-size:1.1rem;}

    .habitat__t1{width:100%;}
    .habitat_con_el{width:50%;padding:0 .5rem;margin-bottom:2rem;}
    .habitat_con_el__t1{font-size:1.3rem;}

    .preguntas{margin-top:1rem;padding:1rem 0 3rem 0;}

    .relacionados_el{width:50%;margin-right:.5rem;}
    .relacionados_el:nth-child(2){margin-right:0;}
    .relacionados_el:nth-child(3){display:none;}
    .relacionados_el_des{bottom:1rem;right:1rem;font-size:1.2rem;}

    .preguntas{padding:1rem 0 0 0;}
}

@media (max-width:899px) and (min-width: 600px){
    .content{padding-bottom:7rem;}
    .des{font-size:1.1rem;}

    .habitat__t1{width:calc(50% - 1rem);}
    .habitat_con_el{width:50%;padding:0 3rem;margin-bottom:2rem;}
    .habitat_con_el__t1{font-size:1.5rem;}
    
    .relacionados_el{width:50%;margin-right:1rem;}
    .relacionados_el:nth-child(3){display:none;}
    .relacionados_el_des{bottom:1rem;right:1rem;font-size:1.2rem;}

    .preguntas{padding:3rem 0 0 0;}
}

@media (max-width:1199px) and (min-width: 900px){
    .content{padding-bottom:7rem;}
    .des{font-size:1.1rem;}

    .habitat_con_el{width:33.3%;padding:0 3rem;margin-bottom:2rem;}
    .habitat_con_el__t1{font-size:1.5rem;}

    .relacionados_el{width:33.3%;margin-right:1rem;}
    .relacionados_el_des{bottom:1rem;right:1rem;font-size:1.4rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .content{padding-bottom:8rem;}
    .des{font-size:1.2rem;}

    .habitat_con_el{width:33%;padding:0 3rem;margin-bottom:2rem;}
    .habitat_con_el__t1{font-size:1.5rem;}

    .relacionados_el{width:calc(33% - 1rem);margin-right:1rem;}
    .relacionados_el_des{bottom:1rem;right:1rem;font-size:1.4rem;}
}

@media (min-width:1400px){
    .content{padding-bottom:8rem;}
    .des{font-size:1.3rem;}

    .habitat_con_el{width:33%;padding:0 3rem;margin-bottom:2rem;}
    .habitat_con_el__t1{font-size:1.6rem;}

    .relacionados_el{width:calc(33% - 1rem);margin-right:1rem;}
    .relacionados_el_des{bottom:1rem;right:1rem;font-size:1.4rem;}
}