/* Contenido */
.contenido_content{display:flex;flex-wrap:wrap;}
.contenido_des, .contenido_form, .contacto_links{width:50%;}
.contenido_des__title{color:#2f6690;font-weight:600;margin-bottom:1rem;}
.contenido_des__link{text-decoration:none;display:block;}
.contenido_des__link, .contenido_des__des{color:#636363;font-weight:300;margin-bottom:.5rem;}
.contenido_des__des.last{margin-bottom:1rem;}
.contenido_des__des a{text-decoration:underline;color:#636363;}

/* Contacto directo */
.contacto_links_title{color:#2f6690;font-weight:600;margin-bottom:1rem;}
.contacto_links_el{display:block;color:#636363;text-decoration:none;margin-bottom:.3rem;}
.contacto_links_el:nth-child(5){margin-bottom:1rem;}
.contacto_links_social{display:flex;}
.contacto_links_social_el img{height:1.5rem;width:auto;margin-right:1rem;}

/* Form */
.form_input{display:block;width:100%;margin-bottom:.5rem;border-top-style:hidden;border-right-style:hidden;border-left-style:hidden;border-bottom-style:hidden;background-color:#e6f3f7;outline:none;padding:.5rem 1rem;}
.form_input::placeholder{color:#2f6690;}
textarea.form_input{margin-bottom:1rem;}
.form_send{background-color:var(--yellow);color:var(--navy);width:fit-content;padding:.6rem 2rem;}

/* Mapa */
.mapa img{width:100%;}
.mapa_des{position:absolute;top:50%;left:50%;z-index:2;transform:translate(-50%, -50%);color:#fff;text-align:center;}

/* Responsive style */
@media (max-width: 599px){
    .contenido_des, .contenido_form, .contacto_links{width:100%;}
    .contenido_des{margin-bottom:2rem;}
    .contenido_des__title, .contacto_links_title{font-size:1.3rem;}
    .contenido_des__link, .contenido_des__des, .contacto_links_el{font-size:1rem;}

    .form_input{font-size:1.1rem;}
    .mapa_des{font-size:1.8rem;line-height:2rem;width:100%;max-width:20rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .contenido_des, .contenido_form, .contacto_links{width:100%;}
    .contenido_des{margin-bottom:2rem;}
    .contenido_des__title, .contacto_links_title{font-size:1.5rem;}
    .contenido_des__link, .contenido_des__des, .contacto_links_el{font-size:1.1rem;}

    .form_input{font-size:1.1rem;}
    .mapa_des{font-size:2rem;max-width:25rem;line-height:2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .contenido_des__title, .contacto_links_title{font-size:1.3rem;}
    .contenido_des{padding-right:1rem;}
    .contenido_form{padding-left:1rem;}
    .contenido_des__link, .contenido_des__des, .contacto_links_el{font-size:1rem;}

    .form_input{font-size:1rem;}
    .mapa_des{font-size:2rem;max-width:25rem;line-height:2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .contenido_des__title, .contacto_links_title{font-size:1.5rem;}
    .contenido_des{padding-right:2rem;}
    .contenido_form{padding-left:2rem;}
    .contenido_des__link, .contenido_des__des, .contacto_links_el{font-size:1.1rem;}

    .form_input{font-size:1.1rem;}
    .mapa_des{font-size:2.5rem;max-width:25rem;line-height:2.5rem;}
}

@media (min-width:1400px){
    .contenido_des__title, .contacto_links_title{font-size:1.6rem;}
    .contenido_des{padding-right:3rem;}
    .contenido_form{padding-left:3rem;}
    .contenido_des__link, .contenido_des__des, .contacto_links_el{font-size:1.2rem;}

    .form_input{font-size:1.2rem;}
    .mapa_des{font-size:3rem;max-width:35rem;line-height:3rem;}
}