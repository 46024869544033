.container{width:100%;height:100vh;background:rgb(59,79,153);background:linear-gradient(136deg, rgba(59,79,153,1) 0%, rgba(43,54,94,1) 100%);position:relative;overflow:hidden;}
.container_background{position:absolute;top:-2%;left:0;width:100%;height:105%;object-fit:cover;}

.content_vid_container{background-color:#2a2a32;padding:2rem;border-radius:.5rem;}
.content{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);z-index:2;}
.content_vid{border-radius:2rem;overflow:hidden;position:relative;width:100%;height:0;padding-top:56.2%;}
.content_vid iframe{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.disclaimer{color:#fff;padding-top:.5rem;}

.logos{padding-top:1rem;z-index:3;display:flex;justify-content:center;align-items:center;margin:auto;}
.logos_llenosdevida{color:#e9bc00;font-family:'Bebas Neue',cursive;}

/* Responsive style */
@media (max-width: 599px){
    .content, .logos{width:calc(100% - 1rem);}
    .content_vid_container{padding:1rem .5rem;}
    .content_vid{border-radius:.5rem;}
    .disclaimer{font-size:1rem;}
    .logos{flex-direction:column;}
    .logos_gam{height:3rem;width:auto;margin-bottom:1rem;}
    .logos_llenosdevida{font-size:1.5rem;margin-bottom:1rem;display:none;}
    .logos_triatlon{height:3rem;width:auto;}
}

@media (max-width:899px) and (min-width: 600px){
    .content, .logos{width:calc(100% - 2rem);}
    .content_vid_container{padding:2rem 1rem;}
    .disclaimer{font-size:1.2rem;}
    .logos_gam{height:2rem;width:auto;margin-right:1.5rem;}
    .logos_llenosdevida{font-size:1.5rem;margin-right:1.5rem;}
    .logos_triatlon{height:2rem;width:auto;}
}

@media (max-width:1199px) and (min-width: 900px){
    .content, .logos{width:calc(100% - 8rem);}
    .disclaimer{font-size:1.2rem;}
    .logos_gam{height:3rem;width:auto;margin-right:1.5rem;}
    .logos_llenosdevida{font-size:1.8rem;margin-right:1.5rem;}
    .logos_triatlon{height:3rem;width:auto;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .content, .logos{width:calc(100% - 12rem);}
    .disclaimer{font-size:1.2rem;}
    .logos_gam{height:3rem;width:auto;margin-right:2rem;}
    .logos_llenosdevida{font-size:2rem;margin-right:2rem;}
    .logos_triatlon{height:3rem;width:auto;}
}

@media (min-width:1400px){
    .content, .logos{width:calc(100% - 20rem);max-width:1200px;}
    .disclaimer{font-size:1.5rem;}
    .logos_gam{height:3rem;width:auto;margin-right:2rem;}
    .logos_llenosdevida{font-size:2rem;margin-right:2rem;}
    .logos_triatlon{height:3rem;width:auto;}
}