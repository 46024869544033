.mar .contenido_des{background-color:#3a7ca5;}
.mar .contenido_ops{background-color:#2f6690;}

/* CONTENIDO */
.contenido{display:flex;flex-wrap:wrap;position:relative;}
.contenido_des{background-color:#16425b;color:#fff;}
.contenido_des__t2{margin-bottom:.5rem;}
.contenido_des__btn a{text-decoration:none;background-color:var(--yellow);color:var(--navy);padding:.5rem 1rem;display:block;width:fit-content;margin-top:1.5rem;}
.contenido_des__sep{width:100%;height:1px;background-color:var(--yellow);}
.contenido_ops{background-color:#3a7ca5;}
.contenido_des,.contenido_ops{width:50%;}
.contenido_ops__el{color:#fff;position:relative;margin-bottom:.5rem;padding:.35rem 1rem .1rem 1rem;}

.contenido_ops__el.tierra:nth-child(1n){background-color:#3c7699;}
.contenido_ops__el.tierra:nth-child(2n){background-color:#488aaf;}
.contenido_ops__el.tierra:nth-child(3n){background-color:#75a4c0;}

.contenido_ops__el a{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .contenido_des,.contenido_ops{width:100%;padding:3rem 1rem;}
    .contenido_des div{width:100%;}
    .contenido_des__t1{font-size:1.6rem;line-height:2.1rem;}
    .contenido_des__t2{font-size:1.2rem;}
    .contenido_des__btn a{font-size:1.1rem;}
    .contenido_des__sep{margin:1rem 0;}
    .contenido_ops__el{width:100%;font-size:1.8rem;}
    .contenido_ops__el:last-child{margin-bottom:0;}
}

@media (max-width:899px) and (min-width: 600px){
    .contenido_des,.contenido_ops{width:100%;padding:4rem 3rem;}
    .contenido_des div{width:100%;}
    .contenido_des__t1{font-size:2.2rem;line-height:2.7rem;max-width:30rem;}
    .contenido_des__t2{font-size:1.2rem;}
    .contenido_des__sep{margin:1rem 0;}
    .contenido_ops__el{width:100%;font-size:2rem;}
    .contenido_ops__el:last-child{margin-bottom:0;}
}

@media (max-width:1199px) and (min-width: 900px){
    .contenido_des,.contenido_ops{padding:3rem 3rem 10rem;}
    .contenido_des div{width:100%;}
    .contenido_des__t1{font-size:1.8rem;line-height:2.3rem;}
    .contenido_des__t2{font-size:1.1rem;}
    .contenido_des__btn a{font-size:1.1rem;}
    .contenido_des__sep{margin:1rem 0;}
    .contenido_ops__el{width:100%;font-size:1.8rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .contenido_des,.contenido_ops{padding:4rem 4rem 12rem;}
    .contenido_des div{width:30rem;margin-left:auto;}
    .contenido_des__t1{font-size:2rem;line-height:2.5rem;}
    .contenido_des__t2{font-size:1.2rem;}
    .contenido_des__btn a{font-size:1.1rem;}
    .contenido_des__sep{margin:1rem 0;}
    .contenido_ops__el{width:28rem;font-size:1.8rem;}
}

@media (min-width:1400px){
    .contenido_des,.contenido_ops{padding:5rem 5rem 10rem;}
    .contenido_des div{width:32rem;margin-left:auto;}
    .contenido_des__t1{font-size:2.2rem;line-height:2.7rem;}
    .contenido_des__t2{font-size:1.4rem;}
    .contenido_des__btn a{font-size:1.2rem;}
    .contenido_des__sep{margin:1rem 0;}
    .contenido_ops__el{width:32rem;font-size:2rem;}
}