/* Modal */
.overlay{position:fixed;z-index:10;width:100%;height:100vh;top:0;left:0;background-color:rgb(0 0 0 / 25%);}
.modalContainer{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);box-shadow:0px 0px 18px 0px rgb(0 0 0 / 75%);}
.modalContainer img{width:100%;pointer-events:none;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .modalContainer{width:95%;}
}

@media (max-width:899px) and (min-width: 600px){
    .modalContainer{width:95%;}
}

@media (max-width:1199px) and (min-width: 900px){
    .modalContainer{width:50rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .modalContainer{width:55rem;}
}

@media (min-width:1400px){
    .modalContainer{width:65rem;}
}