/* Pregunta 
.preguntas_content{display:flex;flex-wrap:wrap;}
.pregunta{border:1px solid rgb(27 55 98 / 30%);border-radius:.5rem;width:calc(33.3% - 1rem);padding:2rem;-webkit-box-shadow:0 1.5px 1.5px 0 rgba(0,0,0,.08);box-shadow:0 1.5px 1.5px 0 rgba(0,0,0,.08);}
.pregunta:nth-child(-n+3){margin-bottom:1.5rem;}
.pregunta:nth-child(3), .pregunta:nth-child(6){margin-left:1rem;}
.pregunta:nth-child(1), .pregunta:nth-child(4){margin-right:1rem;}
.pregunta:nth-child(2), .pregunta:nth-child(5){margin-right:.5rem;margin-left:.5rem;}
.pregunta_pregunta{color:var(--navy);margin-bottom:1rem;font-weight:600;}
.pregunta_respuesta{color:#636363;} */

.preguntas{background-color:#16425b;}
.preguntas_title{color:#fff;width:100%;max-width:900px;margin:auto;margin-bottom:1rem;}
.preguntas_title a{text-decoration:underline;color:#fff;}
.faq_accordion{width:100%;max-width:900px;margin:auto;}
.preguntas_item{color:#fff;}
.preguntas_item__title{color:#9acfdf;cursor:pointer;}
.preguntas_item:nth-child(odd) .preguntas_item__title{background-color:#214f68;}
.preguntas_item:nth-child(even) .preguntas_item__title{background-color:#2b5c74;}

/* Responsive style */
@media (max-width: 599px){
    .preguntas_title{font-size:1.2rem;margin-bottom:1.5rem;}
    .preguntas_item__title{font-size:1.1rem;padding:1rem;}
    .preguntas_item__respuesta{font-size:1.1rem;padding:1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .preguntas_title{font-size:1.2rem;padding-right:10rem;margin-bottom:1.5rem;}
    .preguntas_item__title{font-size:1.2rem;padding:1rem 2rem;}
    .preguntas_item__respuesta{font-size:1.2rem;padding:2rem 2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .preguntas_title{font-size:1.2rem;padding-right:20rem;margin-bottom:1.5rem;}
    .preguntas_item__title{font-size:1.2rem;padding:1rem 2rem;}
    .preguntas_item__respuesta{font-size:1.2rem;padding:2rem 2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .preguntas_title{font-size:1.2rem;padding-right:25rem;margin-bottom:1.5rem;}
    .preguntas_item__title{font-size:1.2rem;padding:1rem 2rem;}
    .preguntas_item__respuesta{font-size:1.2rem;padding:2rem 2rem;}
}

@media (min-width:1400px){
    .preguntas_title{font-size:1.3rem;padding-right:26rem;margin-bottom:2rem;}
    .preguntas_item__title{font-size:1.3rem;padding:1rem 2rem;}
    .preguntas_item__respuesta{font-size:1.3rem;padding:2rem 2rem;}
}

/*
@media (max-width: 599px){
    .pregunta{width:100%;margin-right:0!important;margin-left:0!important;margin-bottom:1.5rem!important;padding:2rem 1rem;}
    .pregunta:last-child{margin-bottom:0!important;}
    .pregunta_pregunta{font-size:1.3rem;line-height:1.5rem;}
    .pregunta_respuesta{font-size:1.1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .pregunta{width:calc(50% - 1rem);}
    .pregunta:nth-child(odd){margin-right:1rem;margin-left:0;}
    .pregunta:nth-child(even){margin-left:1rem;margin-right:0;}
    .pregunta:nth-child(-n+4){margin-bottom:1.5rem;}
    .pregunta_pregunta{font-size:1.5rem;line-height:1.5rem;}
    .pregunta_respuesta{font-size:1.2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .pregunta{padding:2rem 1rem;}
    .pregunta_pregunta{font-size:1.5rem;line-height:1.5rem;}
    .pregunta_respuesta{font-size:1.2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .pregunta_pregunta{font-size:1.5rem;line-height:1.5rem;}
    .pregunta_respuesta{font-size:1.2rem;}
}

@media (min-width:1400px){
    .pregunta_pregunta{font-size:1.6rem;line-height:1.6rem;}
    .pregunta_respuesta{font-size:1.2rem;}
}*/