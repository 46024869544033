/* Tarifa y horarios */
.tarifas{background: linear-gradient(to right,#2f6690 0%,#2f6690 50%,#3a7ca5 50%,#3a7ca5 100%);}
.tarifas_content{display:flex;flex-wrap:wrap;}
.tarifas_title, .tarifas_des{width:50%;}
.tarifas_title{color:#81c3d7;text-align:right;margin-left:auto;}
.tarifas_title div{margin-left:auto;}
.tarifas_des{color:#fff;}
.tarifas_des__t1{margin-bottom:.5rem;}

/* Recomendaciones */
.recomendaciones{background-color:#81c3d7;display:flex;flex-wrap:wrap;}
.recomendaciones_des, .recomendaciones_con{width:50%;position:relative;}
.recomendaciones_con{color:#2f6690;}
.recomendaciones_con__el{margin-bottom:.8rem;padding-bottom:.8rem;border-bottom:1px solid rgb(255, 255, 255, 50%);}
.recomendaciones_con__el:last-child{margin-bottom:0;padding-bottom:0;border-bottom:0;}
.recomendaciones_des{display:flex;flex-direction:column;overflow:hidden;}
.recomendaciones_des_tramado{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.recomendaciones_des__title{color:#fff;position:relative;z-index:2;text-align:right;margin-left:auto;}
.recomendaciones_des_animaciones{flex-grow:1;position:relative;z-index:2;}
.recomendaciones_des_caballito{position:absolute;bottom:0;z-index:3;}
.recomendaciones_des_caballito_fondo{position:absolute;top:0;z-index:2;}

/* Ruta */
.ruta{background-color:#3a7ca5;position:relative;}
.ruta_content{display:flex;flex-wrap:wrap;align-items:center;}
.ruta_des, .ruta_con{width:50%;}
.ruta_des__t1{color:#fff;margin-bottom:1.5rem;}
.ruta_des__t2{color:#81c3d7;}
.ruta_con_el{display:flex;align-items:center;margin-bottom:1.5rem;color:#81c3d7;}
.ruta_con_el img{width:3rem;margin-right:1rem;}
.ruta_con_link{text-decoration:none;display:block;background-color:var(--yellow);width:fit-content;padding:.5rem 1rem;color:var(--navy);}

/* Ruta */
.plano{position:relative;background:rgb(0,0,0);background:linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 60%);}
.plano_mapa{margin:auto;width:100%;max-width:60rem;}
.text{position:absolute;top:0;left:50%;}

/* Responsive style */
@media (max-width: 599px){
    .tarifas{background:none}
    .tarifas_content{padding:0;}
    .tarifas_title, .tarifas_des{width:100%;}
    .tarifas_title{padding:3rem 1rem;text-align:left;background-color:#2f6690;}
    .tarifas_des{padding:3rem 1rem;background-color:#3a7ca5;}
    .tarifas_title div{font-size:2rem;line-height:2rem;margin-left:initial;}
    .tarifas_des__t1{font-size:1.5rem;}
    .tarifas_des__t2{font-size:1.1rem;}

    .recomendaciones_des, .recomendaciones_con{width:100%;}
    .recomendaciones_des__title{font-size:1.8rem;padding:3rem 1rem;line-height:1.8rem;text-align:left;margin-left:initial;}
    .recomendaciones_des_animaciones{padding-bottom:20rem;}
    .recomendaciones_des_tramado{height:calc(100% + 4rem);top:-2rem;}
    .recomendaciones_des_caballito{width:100%;height:auto;}
    .recomendaciones_des_caballito_fondo{display:none;}
    .recomendaciones_con{padding:3rem 1rem 5rem 1rem;font-size:1.1rem;}

    .ruta_des, .ruta_con{width:100%;}
    .ruta_des__t1{font-size:1.6rem;line-height:1.5rem;}
    .ruta_des__t2{font-size:1.1rem;max-width:30rem;margin-bottom:2rem;}
    .ruta_con_el{font-size:1rem;}

    .ruta_content{padding-bottom:6rem;}
    .text{width:12rem;transform:translate(calc(-100% + 1rem),-3.1rem);}

    .plano_content{padding-top:3.5rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .tarifas{background: linear-gradient(to bottom,#2f6690 0%,#2f6690 50%,#3a7ca5 50%,#3a7ca5 100%);}
    .tarifas_title, .tarifas_des{width:100%;}
    .tarifas_title{padding-bottom:3rem;text-align:left;}
    .tarifas_des{padding-top:3rem;padding-right:6rem;}
    .tarifas_title div{font-size:2.5rem;line-height:2.5rem;max-width:27rem;margin-left:initial;}
    .tarifas_des__t1{font-size:1.6rem;}
    .tarifas_des__t2{font-size:1.2rem;}

    .recomendaciones_des, .recomendaciones_con{width:100%;}
    .recomendaciones_des_animaciones{padding-bottom:20rem;}
    .recomendaciones_des_tramado{height:calc(100% + 4rem);top:-2rem;}
    .recomendaciones_des__title{font-size:2rem;padding:3rem 2rem;max-width:32rem;line-height:2rem;text-align:left;margin-left:initial;}
    .recomendaciones_des_caballito{height:25rem;width:auto;right:3rem;}
    .recomendaciones_des_caballito_fondo{height:15rem;width:auto;right:25rem;}
    .recomendaciones_con{padding:3rem 2rem 5rem 2rem;font-size:1.2rem;}

    .ruta_des, .ruta_con{width:100%;}
    .ruta_des__t1{font-size:1.8rem;line-height:1.8rem;max-width:30rem;}
    .ruta_des__t2{font-size:1.2rem;max-width:30rem;margin-bottom:2rem;}
    .ruta_con_el{font-size:1.1rem;}

    .ruta_content{padding-bottom:6rem;}
    .text{width:15rem;transform:translate(calc(-100% - 2rem), -3.9rem);}

    .plano_content{padding-top:5rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .tarifas_content{padding-top:4.5rem;padding-bottom:4.5rem;}
    .tarifas_title{padding-right:1.5rem;}
    .tarifas_title div{font-size:2.2rem;line-height:2.2rem;max-width:23.5rem;}
    .tarifas_des{padding-left:1.5rem;}
    .tarifas_des__t1{font-size:1.6rem;}
    .tarifas_des__t2{font-size:1.2rem;}

    .recomendaciones_des_tramado{height:calc(100% + 4rem);top:-2rem;}
    .recomendaciones_con{padding:4.5rem 3rem 5rem 1.5rem;font-size:1.1rem;}
    .recomendaciones_des__title{font-size:2rem;padding:3rem 1.5rem 0 0;max-width:30rem;line-height:2rem;}
    .recomendaciones_des_caballito{height:calc(100% - 2rem);width:auto;right:1.5rem;}
    .recomendaciones_des_caballito_fondo{height:60%;width:auto;right:20rem;}

    .ruta_des{padding-right:1.5rem;}
    .ruta_con{padding-left:1.5rem;}
    .ruta_des__t1,.ruta_des__t2{text-align:right;margin-left:auto;}
    .ruta_des__t1{font-size:1.5rem;line-height:1.5rem;max-width:25rem;}
    .ruta_des__t2{font-size:1.2rem;max-width:25rem;}

    .ruta_content{padding-bottom:6rem;}
    .text{width:15rem;transform:translate(1.5rem, -3.9rem);}

    .plano_content{padding-bottom:6rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .tarifas_title{padding-right:2rem;}
    .tarifas_title div{font-size:2.5rem;line-height:2.5rem;max-width:26.9rem;}
    .tarifas_des{padding-left:2rem;}
    .tarifas_des__t1{font-size:1.6rem;}
    .tarifas_des__t2{font-size:1.2rem;}

    .recomendaciones_con{padding:4rem 4rem 6rem 2rem;font-size:1.2rem;}
    .recomendaciones_des__title{font-size:2rem;padding:4rem 2rem 0 0;max-width:30rem;line-height:2rem;}
    .recomendaciones_des_caballito{height:calc(100% - 1rem);width:auto;right:2rem;}
    .recomendaciones_des_caballito_fondo{height:60%;width:auto;right:22rem;}

    .ruta_des{padding-right:2rem;}
    .ruta_con{padding-left:2rem;}
    .ruta_des__t1,.ruta_des__t2{text-align:right;margin-left:auto;}
    .ruta_des__t1{font-size:1.5rem;line-height:1.5rem;max-width:25rem;}
    .ruta_des__t2{font-size:1.2rem;max-width:25rem;}

    .ruta_content{padding-bottom:6rem;}
    .text{width:16rem;transform:translate(2rem, -4.2rem);}

    .plano_content{padding-bottom:6rem;}
}

@media (min-width:1400px){
    .tarifas_content{padding-top:6rem;padding-bottom:6rem;}
    .tarifas_title{padding-right:2.5rem;}
    .tarifas_title div{font-size:3rem;line-height:3rem;max-width:32rem;}
    .tarifas_des{padding-left:2.5rem;}
    .tarifas_des__t1{font-size:2rem;}
    .tarifas_des__t2{font-size:1.3rem;}

    .recomendaciones_con{padding:6rem 4rem 6rem 2.5rem;font-size:1.3rem;}
    .recomendaciones_des__title{font-size:2.2rem;padding:4rem 2rem 0 0;max-width:33rem;line-height:2.2rem;}
    .recomendaciones_des_caballito{height:calc(100% - 1rem);width:auto;right:2.5rem;}
    .recomendaciones_des_caballito_fondo{height:60%;width:auto;right:23rem;}

    .ruta_des{padding-right:2.5rem;}
    .ruta_con{padding-left:2.5rem;}
    .ruta_con_el{font-size:1.1rem;}
    .ruta_des__t1,.ruta_des__t2{text-align:right;margin-left:auto;}
    .ruta_des__t1{font-size:1.8rem;line-height:2rem;max-width:30rem;}
    .ruta_des__t2{font-size:1.3rem;max-width:28rem;}

    .ruta_content{padding-bottom:6rem;}
    .text{width:18rem;transform:translate(2.5rem, -4.7rem);}

    .plano_content{padding-bottom:6rem;}
}