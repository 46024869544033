/* CONTENIDO DE TÍTULOS */
.header_title{background-color:#0075c9;}
.header_title.costa{background-color:#006853;}
.header_title.tierra{background-color:#bc955c;}

.content{padding-bottom:8rem;}
.contenido_des{width:calc(50% - 4rem);margin-left:auto;margin-bottom:4rem;}
.contenido__t1{color:#000000;font-weight:bold;}
.contenido__t1.cientifico{font-style:italic;}
.contenido__t2{color:#636363;margin-bottom:1rem;}
.contenido__estatus{margin-top:1rem;width:100%;max-width:25rem;}

.contenido_images{display:flex;flex-wrap:wrap;}
.contenido_images__el{width:50%;}

.contenido_des:nth-child(3){width:50%;margin-left:0;margin-bottom:0;padding-top:4rem;}

/* RESPONSIVE STYLE */
@media (max-width: 599px){
    .des{font-size:1.1rem;}

    .content{padding-bottom:3rem;}
    .contenido_des,.contenido_des:nth-child(3){width:100%;}
    .contenido_des{margin-bottom:3rem;}
    .contenido_des:nth-child(3){padding-top:3rem;}
    .contenido_images__el{width:100%;}
}

@media (max-width:899px) and (min-width: 600px){
    .des{font-size:1.1rem;}

    .content{padding-bottom:3rem;}
    .contenido_des,.contenido_des:nth-child(3){width:100%;}
    .contenido_des{margin-bottom:3rem;}
    .contenido_des:nth-child(3){padding-top:3rem;}
    .contenido__t1{width:calc(50% - 2rem);}
}

@media (max-width:1199px) and (min-width: 900px){
    .des{font-size:1.2rem;}

    .contenido_des{width:calc(50% - 2rem);}
}

@media (max-width:1399px) and (min-width: 1200px){
    .des{font-size:1.2rem;}
}

@media (min-width:1400px){
    .des{font-size:1.3rem;}
}