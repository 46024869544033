/* Talleres */
.talleres{background-color:#0094ff;}
.talleres_content{display:flex;flex-wrap:wrap;}
.talleres_title, .talleres_des{width:50%;}
.talleres_title{color:#fff;text-align:right;}
.talleres_title div{margin-left:auto;}
.talleres_des__t1{color:#fff;margin-bottom:1rem;}
.talleres_des__t1:nth-child(2){margin-bottom:2rem;}
.talleres_des__title{color:#16425b;margin-bottom:.5rem;}
.talleres_des__img{width:100%;margin-bottom:1.5rem;}
.talleres_des__btn{background-color:var(--yellow);color:var(--navy);text-decoration:none;padding:.5rem 1rem;display:block;width:fit-content;}
.talleres_tortuga{position:absolute;width:45%;left:0;bottom:2rem;pointer-events:none;z-index:2;}

/* Responsive style */
@media (max-width: 599px){
    .talleres_title, .talleres_des{width:100%;}
    .talleres_title div{margin-left:initial;text-align:left;}
    .talleres_title, .talleres_des__title{font-size:2rem;line-height:2rem;}
    .talleres_title div{max-width:26rem;margin-bottom:1rem;}
    .talleres_des__t1, .talleres_des__btn{font-size:1.1rem;}
    
    .talleres_tortuga{width:85%;bottom:-16%;}
}

@media (max-width:899px) and (min-width: 600px){
    .talleres_title, .talleres_des{width:100%;}
    .talleres_title div{margin-left:initial;text-align:left;}
    .talleres_title, .talleres_des__title{font-size:2.5rem;line-height:2.5rem;}
    .talleres_title div{max-width:33rem;margin-bottom:1rem;}
    .talleres_des__t1, .talleres_des__btn{font-size:1.2rem;}

    .talleres_tortuga{width:70%;bottom:-23%;}
}

@media (max-width:1199px) and (min-width: 900px){
    .talleres_title, .talleres_des__title{font-size:2.2rem;line-height:2.2rem;}
    .talleres_title div{max-width:26rem;}
    .talleres_des{padding-left:3rem;}
    .talleres_des__t1, .talleres_des__btn{font-size:1.2rem;}
    .talleres_content{padding-bottom:8rem;}
    .talleres_tortuga{width:50%;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .talleres_title, .talleres_des__title{font-size:2.5rem;line-height:2.5rem;}
    .talleres_title div{width:29rem;}
    .talleres_des{padding-left:4rem;}
    .talleres_des__t1, .talleres_des__btn{font-size:1.2rem;}
    .talleres_content{padding-bottom:8rem;}
}

@media (min-width:1400px){
    .talleres_title, .talleres_des__title{font-size:3rem;line-height:3rem;}
    .talleres_title div{width:33rem;}
    .talleres_des{padding-left:4rem;}
    .talleres_des__t1, .talleres_des__btn{font-size:1.3rem;}
    .talleres_content{padding-bottom:8rem;}
    .talleres_tortuga{width:45%;max-width:45rem;}
}