/* Centro de Investigación */
.ciomc_content{display:flex;flex-wrap:wrap;}
.ciomc_des, .ciomc_links{width:50%;}
.ciomc_des{background-color:#16425b;}
.ciomc_links{display:grid;}
.ciomc_links__el{color:#fff;position:relative;}
.ciomc_links__el img{width:1.5rem;height:auto;margin-left:.5rem;display:initial;}
.ciomc_links__el:nth-child(1){background-color:#3a7ca5;}
.ciomc_links__el:nth-child(2){background-color:#2f6690;}
.ciomc_links__el_link{position:absolute;top:0;left:0;width:100%;height:100%;}
.ciomc_links__el_prox{display:block;}

.ciomc_des__t1,.ciomc_des__t2{color:#fff;}
.ciomc_des__t1{margin-bottom:2rem;}
.ciomc_des__title{color:#3a7ca5;margin-bottom:.2rem;}
.ciomc_des__sep{display:block;width:100%;height:1px;background-color:var(--yellow);margin:1rem 0;}

/* Responsive style */
@media (max-width: 599px){
    .ciomc_des, .ciomc_links{width:100%;}
    .ciomc_des, .ciomc_links__el{padding:4rem 1rem;}
    .ciomc_links__el_title{font-size:1.6rem;max-width:23rem;}
    .ciomc_links__el_prox{font-size:1rem;}

    .ciomc_des__t1{font-size:1.6rem;line-height:2rem;}
    .ciomc_des__title{font-size:1.4rem;}
    .ciomc_des__t2{font-size:1.1rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .ciomc_des, .ciomc_links{width:100%;}
    .ciomc_des, .ciomc_links__el{padding:4rem 2rem;}
    .ciomc_links__el_title{font-size:1.8rem;max-width:25rem;}
    .ciomc_links__el_prox{font-size:1.1rem;}

    .ciomc_des__t1{font-size:2rem;max-width:29rem;line-height:2.2rem;}
    .ciomc_des__title{font-size:1.6rem;}
    .ciomc_des__t2{font-size:1.2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .ciomc_des, .ciomc_links__el{padding:4rem 3rem;}
    .ciomc_des{padding-bottom:14rem;}
    .ciomc_links__el_title{font-size:1.6rem;max-width:23rem;}
    .ciomc_links__el_prox{font-size:1.1rem;}

    .ciomc_des__t1{font-size:1.6rem;max-width:25rem;line-height:2rem;}
    .ciomc_des__title{font-size:1.4rem;}
    .ciomc_des__t2{font-size:1.1rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .ciomc_des, .ciomc_links__el{padding:4rem;}
    .ciomc_des{padding-bottom:14rem;}
    .ciomc_links__el_title{font-size:1.6rem;max-width:23rem;}
    .ciomc_links__el_prox{font-size:1.1rem;}

    .ciomc_des__t1{font-size:2rem;max-width:27rem;line-height:2.2rem;}
    .ciomc_des__title{font-size:1.6rem;}
    .ciomc_des__t2{font-size:1.2rem;}
}

@media (min-width:1400px){
    .ciomc_des, .ciomc_links__el{padding:4rem;}
    .ciomc_des{padding-bottom:16rem;}
    .ciomc_links__el_title{font-size:2rem;max-width:26rem;}
    .ciomc_links__el_prox{font-size:1.2rem;}

    .ciomc_des__t1{font-size:2.5rem;max-width:34rem;line-height:2.7rem;}
    .ciomc_des__title{font-size:2rem;}
    .ciomc_des__t2{font-size:1.4rem;}
}